export default {
  badge: 'Σήμα',
  open: 'Open',
  close: 'Close',
  confirmEdit: {
    ok: 'OK',
    cancel: 'Cancel',
  },
  dataIterator: {
    noResultsText: 'Δε βρέθηκαν αποτελέσματα',
    loadingText: 'Loading item...',
  },
  dataTable: {
    itemsPerPageText: 'Γραμμές ανά σελίδα:',
    ariaLabel: {
      sortDescending: 'Sorted descending.',
      sortAscending: 'Sorted ascending.',
      sortNone: 'Not sorted.',
      activateNone: 'Activate to remove sorting.',
      activateDescending: 'Activate to sort descending.',
      activateAscending: 'Activate to sort ascending.',
    },
    sortBy: 'Sort by',
  },
  dataFooter: {
    itemsPerPageText: 'Αντικείμενα ανά σελίδα:',
    itemsPerPageAll: 'Όλα',
    nextPage: 'Επόμενη σελίδα',
    prevPage: 'Προηγούμενη σελίδα',
    firstPage: 'Πρώτη σελίδα',
    lastPage: 'Τελευταία σελίδα',
    pageText: '{0}-{1} από {2}',
  },
  dateRangeInput: {
    divider: 'to',
  },
  datePicker: {
    itemsSelected: '{0} selected',
    range: {
      title: 'Select dates',
      header: 'Enter dates',
    },
    title: 'Select date',
    header: 'Enter date',
    input: {
      placeholder: 'Enter date',
    },
  },
  noDataText: 'Χωρίς δεδομένα',
  carousel: {
    prev: 'הקודם חזותי',
    next: 'הבא חזותי',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}',
    },
  },
  calendar: {
    moreEvents: '{0} ακόμη',
    today: 'Today',
  },
  input: {
    clear: 'Clear {0}',
    prependAction: '{0} prepended action',
    appendAction: '{0} appended action',
    otp: 'Please enter OTP character {0}',
  },
  fileInput: {
    counter: '{0} files',
    counterSize: '{0} files ({1} in total)',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      root: 'Πλοήγηση με προορισμούς',
      next: 'Επόμενη σελίδα',
      previous: 'Προηγούμενη σελίδα',
      page: 'Πήγαινε στην σελίδα {0}',
      currentPage: 'Τρέχουσα σελίδα, σελίδα {0}',
      first: 'First page',
      last: 'Last page',
    },
  },
  stepper: {
    next: 'Next',
    prev: 'Previous',
  },
  rating: {
    ariaLabel: {
      item: 'Rating {0} of {1}',
    },
  },
  loading: 'Loading...',
  infiniteScroll: {
    loadMore: 'Load more',
    empty: 'No more',
  },
}
